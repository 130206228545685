.bleeding-disorder-statistics-nav {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    padding: 24px 8.5% 24px 8.5%;
    grid-gap: 12px;
}

.bleeding-disorder-stats-loading-text {
    margin: 24px 0 24px 0;
}

@media screen and (max-width: 1500px) {
    .settings-items {
        padding: 24px 6.5% 24px 6.5%;
    }
}

@media screen and (max-width: 1200px) {
    .settings-items {
        padding: 24px 5.5% 24px 5.5%;
    }
}

@media screen and (max-width: 1100px) {
    .settings-items {
        padding: 24px 2.5% 24px 2.5%;
    }
}

@media screen and (max-width: 850px) {
    .settings-items {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 24px;
        margin: 24px 0 24px 0;
    }

    .settings-items button {
        width: 100%;
        align-self: center;
    }
}