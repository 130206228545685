.newsletter-signup-items .event-question-container {
    border-style: none;
}

.newsletter-signup-items .success {
    padding: 12px 0 12px 0;
}

.event-question-text {
    margin: 12px 0 12px 0;
}