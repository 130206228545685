.display-container {
    box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;

}

.section-container p {
    font-size: 1.4rem;
}

.event-add {
    padding: 24px 8.5% 24px 8.5%;
}

.event-add .active-section {
    padding: 24px 0 24px 0;
}

.event-add-row {
    display: flex;
    justify-content: space-between;
}

.event-add-row label {
    flex-basis: 49%;
    margin: 1%;
}

.event-add-row div {
    flex-basis: 33%;
}

.add-submit-button {
    width: 80%;
    margin-top: 48px;
}

.event-instructions .p-inputtext {
    width: 100%;
}

.event-question-text-container {
    border-style: none none solid none;
}

.event-question-container {
    border-style: none none solid none;
    padding: 12px 0 12px 0;
    border-width: 2px;
}

.question-row {
    display: flex;
    align-content: center;
}

.question-content-container {
    flex-basis: 60%;
    margin: 12px;
    align-self: center;
}

.question-type-container {
    flex-basis: 30%;
    margin: 12px;
    align-self: center;
}

.question-remove-container {
    margin: 12px;
    display: flex;
    align-self: center;
}

.question-remove-container button {
    height: 40%;
    align-self: center;
}

.question-label {
    display: block;
}

.question-header-label {
    text-align: left;
}

.add-question-button-container {
    padding: 24px 0 24px 0;
}

@media screen and (max-width: 1500px) {
    .event-add {
        padding: 24px 6.5% 24px 6.5%;
    }
}

@media screen and (max-width: 1200px) {
    .event-add {
        padding: 24px 5.5% 24px 5.5%;
    }

    .question-row {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    .question-remove-container button {
        width: 100%;
    }

}

@media screen and (max-width: 1100px) {
    .event-add {
        padding: 24px 2.5% 24px 2.5%;
    }

}

@media screen and (max-width: 850px) {
    .question-row {
        grid-template-columns: repeat(1, 1fr);
    }

    .event-add-row {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    }
}