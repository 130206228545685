.previous-year-impact-section {
    padding: 24px 8.5% 24px 8.5%;
    box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
    border-style: none none solid none;
}

.previous-year-impact-section h1 {
    padding: 12px 0 12px 0;
    font-size: 3rem;
}

.previous-year-impact-content-container {
    display: flex;
    justify-content: space-between;
    margin: 24px 0 24px 0;
}

.previous-year-impact-images {
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    max-width: 750px;
    min-width: 750px;
    border-style: solid;
    align-self: center;
}

.previous-year-impact-image-container {
    display: flex;
}

.previous-year-impact-stats {
    align-self: center;
    border-style: solid;
    padding: 24px;
    max-width: 700px;
}

.previous-year-impact-stats-list {
    text-align: left;
    list-style: none;
    padding-left: 12px;
}

.previous-year-impact-stat {
    font-family: 'Poppins', sans-serif;
    font-size: 1.6rem;
}

.previous-year-impact-stats-end-line {
    font-family: 'Poppins', sans-serif;
    font-size: 2rem;
    text-align: center;
}

.previous-year-impact-stat-container {
    align-self: center;
    border-style: none none solid none;
    border-width: 1px;
    border-width: 50%;
}

@media screen and (max-width: 1850px) {
    .previous-year-impact-stats {
        padding: 24px;
        max-width: 600px;
    }

    .previous-year-impact-stat {
        font-size: 1.4rem;
    }

    .previous-year-impact-stats-end-line {
        font-size: 1.6rem;
    }
}

@media screen and (max-width: 1720px) {
    .previous-year-impact-images {
        max-width: 650px;
        min-width: 650px;
    }
}

@media screen and (max-width: 1600px) {
    .previous-year-impact-images {
        max-width: 550px;
        min-width: 550px;
    }
}

@media screen and (max-width: 1500px) {
    .previous-year-impact-section {
        padding: 24px 6.5% 24px 6.5%;
    }
}

@media screen and (max-width: 1450px) {
    .previous-year-impact-stats {
        padding: 24px;
        max-width: 550px;
    }

    .previous-year-impact-stat {
        font-size: 1.3rem;
    }

    .previous-year-impact-stats-end-line {
        font-size: 1.5rem;
    }
}

@media screen and (max-width: 1380px) {
    .previous-year-impact-stats {
        padding: 24px;
        max-width: 500px;
    }

    .previous-year-impact-stat {
        font-size: 1.2rem;
    }

    .previous-year-impact-stats-end-line {
        font-size: 1.4rem;
    }

    .previous-year-impact-images {
        max-width: 500px;
        min-width: 500px;
    }
}

@media screen and (max-width: 1250px) {
    .previous-year-impact-images {
        max-width: 450px;
        min-width: 450px;
    }

    .previous-year-impact-stats {
        padding: 24px;
        max-width: 450px;
    }
}

@media screen and (max-width: 1200px) {
    .previous-year-impact-section {
        padding: 24px 5.5% 24px 5.5%;
    }
}

@media screen and (max-width: 1100px) {
    .previous-year-impact-section {
        padding: 24px 2.5% 24px 2.5%;
    }
}

@media screen and (max-width: 1050px) {
    .previous-year-impact-stats {
        padding: 24px;
        max-width: 400px;
    }

    .previous-year-impact-images {
        max-width: 400px;
        min-width: 400px;
    }
}

@media screen and (max-width: 980px) {
    .previous-year-impact-content-container {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
        margin: 24px 0 24px 0;
    }


    .previous-year-impact-stats {
        padding: 24px;
        max-width: 80%;
        border-style: none;
    }

    .previous-year-impact-stat {
        font-size: 1.6rem;
    }

    .previous-year-impact-stats-end-line {
        font-size: 1.8rem;
    }

    .previous-year-impact-image-container {
        justify-content: center;
    }

    .previous-year-impact-images {
        max-width: 600px;
        min-width: 600px;
    }
}

@media screen and (max-width: 750px) {
    .previous-year-impact-images {
        max-width: 500px;
        min-width: 500px;
    }
}

@media screen and (max-width: 650px) {
    .previous-year-impact-images {
        max-width: 400px;
        min-width: 400px;
    }

    .previous-year-impact-stat {
        font-size: 1.4rem;
    }

    .previous-year-impact-stats-end-line {
        font-size: 1.6rem;
    }

}

@media screen and (max-width: 580px) {
    .previous-year-impact-section h1 {
        font-size: 2.6rem;
    }
}

@media screen and (max-width: 550px) {
    .previous-year-impact-images {
        max-width: 300px;
        min-width: 300px;
    }

    .previous-year-impact-stat {
        font-size: 1.3rem;
    }

    .previous-year-impact-stats-end-line {
        font-size: 1.5rem;
    }

    .previous-year-impact-stats {
        padding: 0;
        max-width: 90%;
        border-style: none;
    }
}

@media screen and (max-width: 480px) {
    .previous-year-impact-section h1 {
        font-size: 2.2rem;
    }
}

@media screen and (max-width: 450px) {

    .previous-year-impact-stat {
        font-size: 1.2rem;
    }

    .previous-year-impact-stats-end-line {
        font-size: 1.4rem;
    }

    .previous-year-impact-stats {
        padding: 0;
        max-width: 90%;
        border-style: none;
    }
}