.stats-control-nav {
    display: flex;
    justify-content: space-between;
    padding: 24px 8.5% 24px 8.5%;
}

.stats-control-nav button {
    flex-basis: 49.5%;
}

@media screen and (max-width: 1500px) {
    .settings-items {
        padding: 24px 6.5% 24px 6.5%;
    }
}

@media screen and (max-width: 1200px) {
    .stats-control-nav {
        padding: 24px 5.5% 24px 5.5%;
    }
}

@media screen and (max-width: 1100px) {
    .stats-control-nav {
        padding: 24px 2.5% 24px 2.5%;
    }
}

@media screen and (max-width: 850px) {
    .stats-control-nav {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 24px;
        margin: 24px 0 24px 0;
    }

    .stats-control-nav button {
        width: 100%;
        align-self: center;
    }
}